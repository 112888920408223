import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDetailDto } from '../dtos/user-detail.dto';
import { Role } from '../models/role.model';
import { Privilege } from '../models/privilege.model';
import { Attachment } from '../models/attachment.model';
import { FileService } from './file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private profilePic: any;

  public connetedUserSubject: BehaviorSubject<UserDetailDto> = new BehaviorSubject(this.setConnectedUserSubject);

  public set setConnectedUserSubject(value: UserDetailDto) {
    this.connetedUserSubject.next(value);
  }

  public get getProfilePic() {
    return this.profilePic;
  }

  public set setProfilePic(picture: any) {
    this.profilePic = picture;
  }

  constructor(public httpService: HttpService, public router: Router, private fileService: FileService, private sanitizer: DomSanitizer) { }

  saveTokenData(data: any, rememberMe: boolean) {
    this.removeTokenData();
    // Save tokenData to local storage
    sessionStorage.setItem('tokenData', JSON.stringify(data));
    // Save tokenData to session storage
    if (rememberMe) localStorage.setItem('tokenData', JSON.stringify(data));

    this.saveRememberMe(rememberMe);
  }

  saveRememberMe(value: boolean) {
    localStorage.setItem('rememberMe', JSON.stringify(value));
  }

  getRememberMe() {
    let stringData = localStorage.getItem('rememberMe');
    let value: boolean = false;
    if (stringData)
      value = JSON.parse(stringData);
    return value;
  }

  removeTokenData() {
    // Remove tokenData from local storage
    if (sessionStorage.getItem('tokenData') != undefined) sessionStorage.removeItem('tokenData');
    // Remove tokenData from ession storage
    if (localStorage.getItem('tokenData') != undefined) localStorage.removeItem('tokenData');

    localStorage.removeItem('rememberMe');
  }


  isAuthenticated() {
    let token = this.getToken();
    let isTokenExist = token != undefined && token != '';
    return isTokenExist;
  }

  public getCurrentUser(): UserDetailDto | undefined {
    let jsonData = undefined;

    // Check local storage
    let stringData = sessionStorage.getItem("user") ?? undefined;
    // Check session storage
    if (stringData == undefined) stringData = localStorage.getItem("user") ?? undefined;

    if (stringData != undefined && typeof stringData === 'string' && stringData != 'undefined' && stringData != '') {
      try {
        jsonData = JSON.parse(stringData);
        this.setConnectedUserSubject = jsonData;
      } catch (error) {
        // Handle the JSON parsing error
        console.error('Error parsing JSON:', error);
      }
    }

    return jsonData;
  }

  public saveUser(user: UserDetailDto, rememberMe: boolean) {
    this.setConnectedUserSubject = user;
    sessionStorage.setItem("user", JSON.stringify(user));
    if (rememberMe) localStorage.setItem('user', JSON.stringify(user));
  }

  public getUserRoles() {
    let user: UserDetailDto | undefined = this.getCurrentUser();
    let roles: Array<Role> = [];
    if (user) {
      roles = user.roles!;
      return roles;
    }
    else return [];
  }

  public getTokenData(): any {
    let jsonData = undefined;
    // Check local storage
    let stringData = sessionStorage.getItem("tokenData") ?? undefined;
    // Check session storage
    if (stringData == undefined) stringData = localStorage.getItem("tokenData") ?? undefined;

    if (stringData != undefined && typeof stringData === 'string' && stringData != 'undefined' && stringData != '') {
      try {
        jsonData = JSON.parse(stringData);
      } catch (error) {
        // Handle the JSON parsing error
        console.error('Error parsing JSON:', error);
      }
    }

    return jsonData;
  }

  public getToken(): string | undefined {
    let token = undefined;
    let tokenData = this.getTokenData();
    if (tokenData)
      token = tokenData?.accessToken ?? undefined;
    return token;
  }

  public getRefreshToken(): string | undefined {
    let tokenData = this.getTokenData();
    let refreshToken = tokenData?.refreshToken ?? undefined;
    return refreshToken;
  }

  public hasRole(rl: string): boolean {
    let res: boolean = false;
    //Parse through authorities
    this.getUserRoles().forEach((role: Role) => {
      //Check if there is a match
      if (role.title == rl) res = true;
    });

    return res;
  }

  public checkRoles(roles: Array<Role>): boolean {
    let check: boolean = false;

    //Parse through authorities
    if (roles.length == 0 || roles == null)
      check = true;

    for (let role of this.getUserRoles()) {
      //Check if there is a match
      if (roles.some(r => r == role.title)) {
        check = true;
        break;
      }
    };

    return check
  }

  /*public hasPrivilege(privilege?: string): boolean {
    let exists: boolean = false;

    if (privilege == undefined || privilege == '') return true;

    for (let role of this.getUserRoles()) {
      for (let priv of role.privileges ?? []) {
        // Check if there is a match
        if (priv.title == privilege) {
          exists = true;
          break;
        }
      }
    };

    return exists;
  }*/

  
  public hasPrivilege(privilege?: string): boolean {
    // If no privilege is specified, return true
    if (!privilege) return true;

    // Check if any role has the specified privilege
    return this.getUserRoles().some(role =>
      role.privileges?.some(priv => priv.title == privilege)
    );

  }

  public hasAnyPrivilege(privileges?: string[]): boolean {
    // If no privileges are provided, return true
    if (!privileges || privileges.length === 0) return true;

    // Get all privileges from the user's roles
    const userPrivileges = this.getUserRoles()
      .flatMap(role => role.privileges?.map(priv => priv.title) ?? []);

    // Check if any of the provided privileges exist in the user's privileges
    return privileges.some(privilege => userPrivileges.includes(privilege));
  }

  /**
   * Summary. Decode jwt token
   * @param token string
   */
  public parseJwt(token: String) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  /**
   * This is the authentication function, it must return the token that will be used to send http requests
   */
  public login(formGroupValue: any): Promise<any> {
    let params = { username: formGroupValue.username, password: formGroupValue.password, grantType: 'password', withRefreshToken: 'true', refreshToken: '' };
    return this.httpService.post('/v1.0/auth/token', params);;
  }


  public refreshToken(): Promise<any> {
    let refreshToken: string | unknown = this.getRefreshToken();
    let params = { username: '', password: '', grantType: 'refreshToken', withRefreshToken: 'true', refreshToken: refreshToken };
    return this.httpService.post('/v1.0/auth/token', params)
      .then((data: any) => {
        this.saveTokenData(data, localStorage.getItem('rememberMe') != undefined);
        return data;
      }).catch(err => {
        this.logout();
        return err;
      });
  }


  public logout() {
    this.removeTokenData();
    this.profilePic = null;
    this.router.navigateByUrl('/auth/sign-in');
  }

  public async getProfilePicture(attachment: Attachment) {
    if (!attachment?.url || this.profilePic) return;
    await this.fileService.getFile(attachment?.url ?? '')
      .then(async (data) => {
        // this.imageSrc = await this.fileService.getImageToDisplay(data, attachment?.type ?? 'png');
        this.profilePic = data;
      }).catch(error => {
        console.log(error);
      });
  }



  passwordPatternValidator(control: FormControl): { [key: string]: boolean } | null {
    const password = control.value;

    // Define a regular expression pattern for the password criteria
    const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-@$!%*?&])[A-Za-z\d@$!%*?&-]{8,}$/;

    if (!pattern.test(password)) {
      return { invalidPassword: true };
    }

    return null;
  }

  matchPassword(newPassword: string, confirmNewPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[newPassword];
      const confirmPasswordControl = formGroup.controls[confirmNewPassword];

      // if (!passwordControl || !confirmPasswordControl) {
      //   return null;
      // }

      // if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordMismatch']) {
      //   return null;
      // }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true })
        return { passwordMismatch: true };
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    }
  }

  confirmPasswordValidator(passwordForm: FormGroup) {
    const newPassword = passwordForm?.value.newPassword ?? '';
    const confirmNewPassword = passwordForm?.value.confirmNewPassword ?? '';
    if (newPassword !== confirmNewPassword) {
      return false;
    }
    return true;
  }

}
